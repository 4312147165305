import React, { useEffect, useState } from "react";
import Loader from 'react-js-loader';
import ConsumerSidebar from "./includes/sidebar";
import ConsumerHeader from "./includes/header";
import ConsumerFooter from "../includes/footer";
import { useDispatch, useSelector } from "react-redux";
import consumerDashboardAction from "../../actions/consumerDashboardAction";
import usersChatAction from '../../actions/chatListAction';
import { Link } from "react-router-dom";
import moment from 'moment';
import { getDatabase, ref, get } from "firebase/database";

const ConsumerDashboard = () => {
    const dispatch = useDispatch();
    const [dashboardDtls, setDashboardDtls] = useState([]);
    const [consumerInfo, setConsumerInfo] = useState([]);
    const [loading, setLoading] = useState(false);
    const [chatList, setChatList] = useState([]);
   
    useEffect(() => {
        setLoading(true);
        setTimeout(() => { setLoading(false); }, 500);
        dispatch(consumerDashboardAction());
        dispatch(usersChatAction());
    }, []);

    const dashboardInfo = useSelector((state) => state.consumerDashboard.consumerDashboard);
    const consumerProfile = useSelector((state) => state.consumerProfile.consumerProfile);
    // get vendor all chat list
    const userChat = useSelector((state) => state.chatList.chatList);

    useEffect(() => {
        if (dashboardInfo && dashboardInfo.datasss && dashboardInfo.datasss.status === true) {
            setDashboardDtls(dashboardInfo.datasss.data);
        }
    }, [dashboardInfo]);
    
    useEffect(() => {
        if (consumerProfile && consumerProfile.datasss && consumerProfile.datasss.status === true) {
            setConsumerInfo(consumerProfile.datasss.data);
        }
    }, [consumerProfile]);

    const numberWithCommas = (x) => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    useEffect(() => {
        const db = getDatabase();
        if (userChat && userChat?.datasss && userChat?.datasss?.status === true) {
            let chatData = userChat.datasss.data.chats;

            let chatFinalData = chatData.map(async (item) => {
                const snapshot = await get(ref(db, `messages/${item?.event_id}_${item?.vendor_id}_${item?.user_id}`));
                const data = snapshot.val();

                if (data) {
                    // for(let key in data){
                    //     if(data[key].sender_id !== consumerInfo.id){
                    //         console.log('test', data[key])
                    //         return {
                    //             ...item,
                    //             message_data: data[key],
                    //           };
                              
                    //     }
                        
                    // }
                  const keys = Object.keys(data).sort((a, b) => b - a);
                  
                  const lastMessage = data[keys[keys.length - 1]];
                  return {
                    ...item,
                    message_data: lastMessage,
                  };
                } else {
                  return {
                    ...item,
                    messag_data: null,
                  };
                }
              });
              Promise.all(chatFinalData).then((finalData) => {
                // Process the finalData array with the transformed chat data
                setChatList(finalData)
              });
            
        }
    }, [userChat]);
    const handleDownloadPDF = async (pdfURL) => {
        const link = document.createElement('a');
        link.href = pdfURL;
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
      
        // Simulate a click event on the link to trigger the download
        link.click();
    };

    return (
        <>
            {loading ? (
                <div className={"loader-item"}>
                    <Loader type="spinner-circle" bgColor={"#FFFFFF"} color={'#FFFFFF'} size={150} />
                </div>
            ) : (
                <>
                    <div className="wrapper">
                        <ConsumerHeader />
                        <section className="sidebar-content-wr consumer-block">
                            <div className="container">
                                <ConsumerSidebar />
                                <div className="right-content-block">
                                <div className="user-title-name">Welcome <span>{consumerInfo?.first_name +' '+ consumerInfo?.last_name}</span></div>
                                    <div className="upper-card-section">
                                        <div className="upper-card-row">
                                            <div className="upper-card-col">
                                                <div className="event-cards">
                                                    <div className="event-card-body">
                                                        <span className="count-sec">
                                                            <p>{(dashboardDtls.eventsCount > 0) ? dashboardDtls.eventsCount : 0}</p>
                                                        </span>
                                                        <Link to="/upcoming-events"><p className="events-heading">My Events</p></Link>
                                                        <img src="./img/events-icon.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="upper-card-col">
                                                <div className="event-cards">
                                                    <div className="event-card-body">
                                                        <span className="count-sec">
                                                            <p>{(dashboardDtls.messagesCount > 0) ? dashboardDtls.messagesCount : 0}</p>
                                                        </span>
                                                        <Link to="/consumer-chats"><p className="events-heading">Messages</p></Link>
                                                        <img src="./img/message.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="upper-card-col">
                                                <div className="event-cards">
                                                    <div className="event-card-body">
                                                        <span className="count-sec">
                                                            <p>{(dashboardDtls.reviewCount > 0) ? dashboardDtls.reviewCount : 0}</p>
                                                        </span>
                                                        <Link to="/consumer-reviews"><p className="events-heading">Recent Reviews</p></Link>
                                                        <img src="./img/recentmessage.png" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="recentSection">
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12">
                                                <div className="right-top-text">
                                                    <h3>My Events</h3>
                                                </div>
                                                <div className="proposalTable table-responsive">
                                                    <table className="table head-design">
                                                        <thead>
                                                            <tr>
                                                                <th>EVENT NAME</th>
                                                                <th>BUDGET</th>
                                                                <th>SERVICE TYPE</th>
                                                                <th>LOCATION</th>
                                                                <th>STARTING DATE</th>
                                                                <th>END DATE</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {(dashboardDtls?.myEvents?.length > 0) ? (
                                                                dashboardDtls?.myEvents?.slice(0, 5).map((data, index) => {
                                                                    return (
                                                                        <tr key={data.id}>
                                                                            <td> <Link to={`/view-event/${data?.id}`}>{data?.event_name ?? '-'}</Link> </td>
                                                                            <td>{(data?.event_budget) ? '$ ' + numberWithCommas(data?.event_budget) : '-' }</td>

                                                                            {/* <td>{(data.event_budget) ? '$ '+ data.event_budget :'-' }</td> */}
                                                                            <td>{data?.event_type ?? '-'}</td>
                                                                            <td>{data?.location ?? '-' }
                                                                                {/* <span className="countryColor"> Australia</span> */}
                                                                            </td>
                                                                            <td>{moment(data?.event_from_date).format('DD-MM-YYYY') ?? '-'}</td>
                                                                            <td>{moment(data?.event_to_date).format('DD-MM-YYYY')?? '-'}</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan={6} align={'center'}> No recent proposal available </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="lastSection">
                                        <div className="row">
                                            <div className="col-lg-4 col-md-12">
                                                <div className="right-top-text">
                                                    <h3>Recent Reviews</h3>
                                                </div>
                                                <div className="reviewSection">
                                                    <div className="accordion" id="accordionRatingReview">
                                                        {(dashboardDtls?.consumerReview?.length > 0) ? (
                                                            dashboardDtls?.consumerReview?.slice(0, 3).map((rev, index) => {
                                                                return (
                                                                    <div className="accordion-item" key={rev.id}>
                                                                        <h2 className="accordion-header" id={"heading" + rev.id}>
                                                                            <button className={(index === 0) ? "accordion-button" : "accordion-button collapsed"} type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + rev.id} aria-expanded="true" aria-controls={"collapse" + rev.id}>
                                                                                {rev.first_name + " " + rev.last_name} <span className="accordianRation">{rev.rating} <i className="fa fa-star"></i></span>
                                                                            </button>
                                                                        </h2>
                                                                        <div id={"collapse" + rev.id} className={(index === 0) ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby={"heading" + rev.id} data-bs-parent="#accordionRatingReview">
                                                                            <div className="accordion-body">
                                                                                {rev.review}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                        ) : (
                                                            <div className="text-center mt-4">
                                                                    <p> No review available  </p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-lg-8 col-md-12 col-12">
                                                <div className="reacentMEssgage">
                                                    <div className="right-top-text">
                                                        <h3>Recent Messages</h3>
                                                    </div>
                                                    <div className="reviewSection">
                                                     
                                                    <div className="accordion" id="accordionRatingMessage">
                                                        {(chatList?.length > 0) ? (
                                                            chatList?.slice(0, 3).map((val, index) => {
                                                                return (
                                                             
                                                                        <div className="accordion-item" key={val.id}>
                                                                            <h2 className="accordion-header" id={"heading" + val.id}>
                                                                                <button className={(index === 0) ? "accordion-button" : "accordion-button collapsed"} type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + val.id} aria-expanded="true" aria-controls={"collapse" + val.id}>
                                                                                {val.vendor_dtl?.first_name + " " + val.vendor_dtl?.last_name} <span style={{color:'rebeccapurple', fontSize:'14px'}}>{val?.event_dtl?.event_name && `( ${val?.event_dtl?.event_name} )`}</span>
                                                                                </button>
                                                                            </h2>
                                                                            <div id={"collapse" + val.id} className={(index === 0) ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby={"heading" + val.id} data-bs-parent="#accordionRatingMessage">
                                                                                    {
                                                                                        val?.message_data?.mesgType === 'TEXT' &&
                                                                                        <div className="accordion-body">
                                                                                            {val?.message_data?.message}
                                                                                        </div>
                                                                                    }
                                                                                   {
                                                                                     val?.message_data?.mesgType === 'PDF' &&
                                                                                     <div className="accordion-body">
                                                                                           <img className='chat-img-src-pdf' src='img/icons8-pdf-96.png'
                                                                                            onClick={() => {
                                                                                                let pdfURL = val?.message_data?.message;
                                                                                                handleDownloadPDF(pdfURL)
                                                                                            }}
                                                                                            alt="Selected Image" />
                                                                                     </div>
                                                                                   }
                                                                                   {val?.message_data?.mesgType === 'IMAGE' && (
                                                                                    <div className="accordion-body">
                                                                                        <img className="chat-img-src img-radius" style={{ height:'100px',width:'100px' }} src={val.message_data.message} alt="image" />
                                                                                    </div>
                                                                                  )}
                                                                            </div>
                                                                        </div>
                                                                 
                                                                );
                                                            })
                                                        ) : (
                                                            <div className="text-center mt-4">
                                                                    <p> No Messages available  </p>
                                                            </div>
                                                        )}
                                                        {/* {chatList?.length > 3 && ( 
                                                                <div className="header-popup-btn-review mt-2 mb-3 d-flex justify-content-center">
                                                                    <Link to='/consumer-chats'>See more</Link>
                                                                </div>
                                                        )} */}
                                                    </div>
                                                </div>

                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <ConsumerFooter />
                    </div>
                </>
            )}
        </>
    );
};

export default ConsumerDashboard;