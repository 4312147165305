import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./includes/header";
import Footer from "./includes/footer";
import staticPagesAction from '../actions/staticPagesAction';
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const ContactSupport = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(staticPagesAction());
    }, []);

    // get vendor all chat list
    const staticData = useSelector((state) => state.staticDataList);
    const [dataList, setDataList] = useState("");

    useEffect(() => {
        if (staticData && staticData?.notification && staticData?.notification?.datasss && staticData?.notification?.datasss?.status === true) {
            setDataList(staticData.notification.datasss.data)
        }
    }, [staticData]);

    useEffect(() => {
        const { pathname, hash } = location;
        if (hash) {
            const targetElement = document.querySelector(hash);
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: "smooth" });
            }
        } else {
            window.scrollTo(0, 0);
        }
    }, [location]);

    return (
        <>
            <ToastContainer />
            <div className="wrapper">
                <Header />
                <section className="privacy-policy-banner">
                    <div className="privacy-policy-banner-img">
                        <figure>
                            <img src="img/contact_us_banner.png" alt="Support + Contact Us" />
                        </figure>
                    </div>
                    <div className="privacy-policy-banner-content">
                        <div className="container">
                            <div className="privacy-policy-heading">
                                <h1>Support and Contact Us</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="privacy-policy-wr" id="Support">
                    <div className="container">
                        <div className="privacy-policy-content">
                            <div className="privacy-policy-text-block faq-about-us">
                                <ul>
                                    <li>
                                        <h3>Support + Contact Us</h3>
                                        <p className="pt-2">
                                            Can’t find an answer to your question? Contact us at <a style={{ color: "#0A93D5" }}>support@ozgigs.com.au</a>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    );
};

export default ContactSupport;
