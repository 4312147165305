import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./includes/header";
import Footer from "./includes/footer";
import { Link } from "react-router-dom";

const VendorInformationPage = () => {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    return (
      <>
        <ToastContainer />
        <div className="wrapper">
          <Header />
          <section className="privacy-policy-banner">
              <div className="privacy-policy-banner-img">
                  <figure>
                      <img src="img/vendor_information_banner.png" alt="Vendor Information" />
                  </figure>
              </div>
              <div className="privacy-policy-banner-content">
                  <div className="container">
                      <div className="privacy-policy-heading">
                          <h1>VENDOR INFORMATION</h1>
                      </div>
                  </div>
              </div>
          </section>
  
          <section className="privacy-policy-wr">
              <div className="container">
                  <div className="privacy-policy-content">
                      <div className="privacy-policy-text-block faq-about-us">
                          <ul>
                              <li>
                                  <h3>Welcome</h3>
                                  <p className="pt-1">
                                      This is a guide to what Ozgigs is, why it's great for your business and how to get started straight away!
                                  </p>
                              </li>
                          </ul>
                          <ul>
                              <li>
                                  <h3>What is Ozgigs?</h3>
                                  <p className="pt-1">
                                      Ozgigs is an online marketplace for the events industry. It’s the fastest way for everyday people to find vendors for their event. 
                                  </p>
                                  <p className="pt-1">
                                      The Ozgigs Homepage Feed showcases vendors’ talent with a promo reel, and the Event Marketplace provides a platform for customers to list their events and the services they require. 
                                  </p>
                              </li>
                          </ul>
                          <ul>
                              <li>
                                  <h3>Why Use Ozgigs?</h3><br/>
                                  <h5>Reel Them In</h5>
                                  <p className="pt-2-1">
                                      Your Ozgigs profile allows you to lead with your promo reel, giving customers an instant look at what your business can provide. 
                                  </p>
                                  <p className="pt-2-1">
                                      Upload a reel in both portrait and landscape formats to maximise browsing from both desktop and mobile. The reel can be up to 4 minutes long in .mp4 and .mov formats. 
                                  </p>
                                  <p className="pt-2-1">
                                      Don’t have a portrait reel? <Link target="_blank" to="https://www.youtube.com/playlist?list=PLCeFmUYGwSZGxKtHFOl76U04y3U6FjFBz" className="remove-a-formate">Check this video tutorial </Link>on how to make one from scratch or to convert an existing landscape video. 
                                  </p>

                                  <h5>Get More Work</h5>
                                  <p className="pt-2-1">
                                      Ozgigs provides an <b><b>Event Marketplace</b></b> for customers to list their Corporate, Wedding, or Private event, and the vendors they require. Browse these events in the marketplace and contact the customer directly to offer your services. 
                                  </p>

                                  <h5>Set Your Own Rates</h5>
                                  <p className="pt-2-1">
                                      Ozgigs gives vendors an affordable and effective platform to connect with potential customers. 
                                  </p>
                                  <p className="pt-2-1">
                                      There are <b><b>no commissions or booking fees.</b></b> Set your own terms and rates. Customers contact, book and pay you <u>directly</u>.
                                  </p>
                                  <p className="pt-2-1">
                                      To be listed on Ozgigs, vendors can choose between a $9.99 monthly subscription fee, or an annual upfront payment of $99.99 and receive two months free.
                                  </p>
                                  <p className="pt-2-1">
                                      We are currently offering a 90 day free trial. Note: you’ll still need to enter your credit card details and choose your subscription, but payment won’t begin until the trial period ends. There are no fees if you cancel before your free trial period ends. 
                                  </p>
                                  <p className="pt-2-1">
                                      The subscription fee is tax deductible and remains the same, no matter how many bookings you receive.  
                                  </p>

                                  <h5>Track Your Profile</h5>
                                  <p className="pt-2-1">
                                      Ozgigs provides a vendor dashboard to track all your important information. See how many customers view your videos, like your profile and enquire about your services.  
                                  </p>  
                                  <p className="pt-2-1">
                                      Customers can give you a rating and a review after the event to highlight their experience with your service. 
                                  </p>
                              </li>
                          </ul>
                          <ul>
                              <li>
                                  <h3>Get Started Now</h3>
                                  <p className="pt-1">
                                      Get started in 3 easy steps.
                                  </p>
                                  <p className="pt-2-1">
                                  <b>1.</b> Go to <Link target="_blank" to="https://ozgigs.com.au/" className="remove-a-formate">ozgigs.com.au</Link> and sign up via email or Google account (please check your junk/spam folder if you don’t get a verification email in your inbox)
                                  </p>
                                  <p className="pt-2-1">
                                  <b>2.</b> Setup your profile 
                                  </p>
                                  <p className="pt-2-1">
                                  <b>3.</b> Start browsing the Event Marketplace for customers. 
                                  </p>
                                  <p className="pt-1">
                                    Check out our step by step <Link target="_blanck" to="https://www.youtube.com/watch?v=1Pvn8bnM9fk&pp=ygUGb3pnaWdz" className="remove-a-formate"> video guide here </Link> or our <Link target="_blanck" to="https://www.ozgigsblog.com.au/post/setting-up-your-ozgigs-vendor-profile" className="remove-a-formate"> blog guide here.</Link> Feel free to contact us at <Link to="mailto:support@ozgigs.com.au" target="_blank" className="remove-a-formate"> support@ozgigs.com.au </Link>if you have any questions or feedback. 
                                  </p>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
          </section>
          <section className="">
              <div className="container">
                  <div className="privacy-policy-content">
                      <div className="privacy-policy-text-block">
                          <div className="freq-asked-que-section">
                              <div className="freq-asked-section">
                                  <div className="accordion-body-sec">
                                      <div className="accordion" id="accordionExample">
                                          <h1 className="fadeInUp wow">Frequently Asked Questions</h1>
                                          <div className="accordion-item fadeInUp wow">
                                              <h2 className="accordion-header" id="headingOne">
                                                  <button
                                                      className="accordion-button"
                                                      type="button"
                                                      data-bs-toggle="collapse"
                                                      data-bs-target="#collapseOne"
                                                      aria-expanded="true"
                                                      aria-controls="collapseOne"
                                                  >
                                                      How do I get work through Ozgigs?
                                                  </button>
                                              </h2>
                                              <div
                                                  id="collapseOne"
                                                  className="accordion-collapse collapse show"
                                                  aria-labelledby="headingOne"
                                                  data-bs-parent="#accordionExample"
                                              >
                                                  <ul>
                                                      <li>
                                                          <p className="pt-1">
                                                          There are three ways for you to reach customers on Ozgigs.
                                                          </p>
                                                          <p className="pt-2">
                                                          <b>1. Promo Reel -</b> Customers can discover your promo reel on the Homepage Feed. If they’re interested, they can send you a direct message within the Ozgigs platform to enquire about your services. 
                                                          </p>
                                                          <p className="pt-2">
                                                          <b>2. Event Marketplace - </b> Customers can list their events and what services they require on the Event Marketplace. You can browse events relevant to your services and contact customers directly within the Ozgigs platform.
                                                          </p>
                                                          <p className="pt-2">
                                                          <b>3. Match notification email -</b> We’ll also notify you if your services match the requirements of an event on the Marketplace. You’re then able to respond to the post via direct message to offer your services. You can opt out of these notifications. 
                                                          </p>
                                                      </li>
                                                  </ul>
                                              </div>
                                          </div>

                                          <div className="accordion-item fadeInUp wow">
                                              <h2 className="accordion-header" id="headingTwo">
                                                  <button
                                                      className="accordion-button collapsed"
                                                      type="button"
                                                      data-bs-toggle="collapse"
                                                      data-bs-target="#collapseTwo"
                                                      aria-expanded="true"
                                                      aria-controls="collapseTwo"
                                                  >
                                                      Can I cancel my free trial at any time?
                                                  </button>
                                              </h2>
                                              <div
                                                  id="collapseTwo"
                                                  className="accordion-collapse collapse"
                                                  aria-labelledby="headingTwo"
                                                  data-bs-parent="#accordionExample"
                                              >
                                                  <div className="accordion-body">
                                                    Yes, there are no fees if you cancel before your free trial period ends.
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="accordion-item fadeInUp wow">
                                              <h2 className="accordion-header" id="headingThree">
                                                  <button
                                                      className="accordion-button collapsed"
                                                      type="button"
                                                      data-bs-toggle="collapse"
                                                      data-bs-target="#collapseThree"
                                                      aria-expanded="true"
                                                      aria-controls="collapseThree"
                                                  >
                                                      What if I don’t have a reel?
                                                  </button>
                                              </h2>
                                              <div
                                                  id="collapseThree"
                                                  className="accordion-collapse collapse"
                                                  aria-labelledby="headingThree"
                                                  data-bs-parent="#accordionExample"
                                              >
                                                  <div className="accordion-body">
                                                    Reels are one of the most effective ways for you to showcase your business to customers. Luckily, they’re quite easy to make. <Link target="_blank" to="https://www.youtube.com/playlist?list=PLCeFmUYGwSZGxKtHFOl76U04y3U6FjFBz" className="remove-a-formate">Check this video tutorial </Link>to get started on your reel.
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="accordion-item fadeInUp wow">
                                              <h2 className="accordion-header" id="headingFour">
                                                  <button
                                                      className="accordion-button collapsed"
                                                      type="button"
                                                      data-bs-toggle="collapse"
                                                      data-bs-target="#collapseFour"
                                                      aria-expanded="true"
                                                      aria-controls="collapseFour"
                                                  >
                                                      Can I get help with setting up my profile?
                                                  </button>
                                              </h2>
                                              <div
                                                  id="collapseFour"
                                                  className="accordion-collapse collapse"
                                                  aria-labelledby="headingFour"
                                                  data-bs-parent="#accordionExample"
                                              >
                                                  <div className="accordion-body">
                                                    Yes, we can help you get started. Check our step-by-step video guide <Link target="-blanck" to="https://www.youtube.com/watch?v=1Pvn8bnM9fk&pp=ygUGb3pnaWdz" className="remove-a-formate"> here on youtube. </Link>If you need more assistance, please email us at <Link target="_blanck" to="mailto:support@ozgigs.com.au" className="remove-a-formate"> support@ozgigs.com.au </Link>and we’ll help you get started asap! 
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="accordion-item fadeInUp wow">
                                              <h2 className="accordion-header" id="headingFive">
                                                  <button
                                                      className="accordion-button collapsed"
                                                      type="button"
                                                      data-bs-toggle="collapse"
                                                      data-bs-target="#collapseFive"
                                                      aria-expanded="true"
                                                      aria-controls="collapseFive"
                                                  >
                                                      Does Ozgigs guarantee that I’ll get work?
                                                  </button>
                                              </h2>
                                              <div
                                                  id="collapseFive"
                                                  className="accordion-collapse collapse"
                                                  aria-labelledby="headingFive"
                                                  data-bs-parent="#accordionExample"
                                              >
                                                  <div className="accordion-body">
                                                  Ozgigs connects you with potential customers, but we can’t guarantee that you’ll get work. You connect with customers to reach an agreement for the services you provide, at the rates that you set.
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="accordion-item fadeInUp wow">
                                              <h2 className="accordion-header" id="headingSix">
                                                  <button
                                                      className="accordion-button collapsed"
                                                      type="button"
                                                      data-bs-toggle="collapse"
                                                      data-bs-target="#collapseSix"
                                                      aria-expanded="true"
                                                      aria-controls="collapseSix"
                                                  >
                                                      How do I know the customers are real?
                                                  </button>
                                              </h2>
                                              <div
                                                  id="collapseSix"
                                                  className="accordion-collapse collapse"
                                                  aria-labelledby="headingSix"
                                                  data-bs-parent="#accordionExample"
                                              >
                                                  <div className="accordion-body">
                                                  As with any business transaction, make sure you have a contract in place. In person meetings and payment before the event are also ways to ensure that you’re not dealing with a potential scam.
                                                  </div>
                                              </div>
                                          </div>
                                          
                                          <div className="accordion-item fadeInUp wow">
                                              <h2 className="accordion-header" id="headingSeven">
                                                  <button
                                                      className="accordion-button collapsed"
                                                      type="button"
                                                      data-bs-toggle="collapse"
                                                      data-bs-target="#collapseSeven"
                                                      aria-expanded="true"
                                                      aria-controls="collapseSeven"
                                                  >
                                                     What if I get a bad customer?
                                                  </button>
                                              </h2>
                                              <div
                                                  id="collapseSeven"
                                                  className="accordion-collapse collapse"
                                                  aria-labelledby="headingSeven"
                                                  data-bs-parent="#accordionExample"
                                              >
                                                  <div className="accordion-body">
                                                  Ozgigs allows a star rating and review for each event. Both the customer and vendor can review each other. You’re also able to reply to a customer's review of your service.
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="accordion-item fadeInUp wow">
                                              <h2 className="accordion-header" id="headingEight">
                                                  <button
                                                      className="accordion-button collapsed"
                                                      type="button"
                                                      data-bs-toggle="collapse"
                                                      data-bs-target="#collapseEight"
                                                      aria-expanded="true"
                                                      aria-controls="collapseEight"
                                                  >
                                                      What if I get a false bad review?
                                                  </button>
                                              </h2>
                                              <div
                                                  id="collapseEight"
                                                  className="accordion-collapse collapse"
                                                  aria-labelledby="headingEight"
                                                  data-bs-parent="#accordionExample"
                                              >
                                                  <div className="accordion-body">
                                                  Please contact us at <Link target="_blanck" to="mailto:support@ozgigs.com.au" className="remove-a-formate"> support@ozgigs.com.au </Link> if you have any issues related to reviews.
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="accordion-item fadeInUp wow">
                                              <h2 className="accordion-header" id="headingNine">
                                                  <button
                                                      className="accordion-button collapsed"
                                                      type="button"
                                                      data-bs-toggle="collapse"
                                                      data-bs-target="#collapseNine"
                                                      aria-expanded="true"
                                                      aria-controls="collapseNine"
                                                  >
                                                      Ozgigs isn’t available in my region.
                                                  </button>
                                              </h2>
                                              <div
                                                  id="collapseNine"
                                                  className="accordion-collapse collapse"
                                                  aria-labelledby="headingNine"
                                                  data-bs-parent="#accordionExample"
                                              >
                                                  <div className="accordion-body">
                                                  We’re working to expand Ozgigs in the coming months. Please let us know if you’d be interested in using Ozgigs for your area. Contact us at <Link target="_blanck" to="mailto:support@ozgigs.com.au" className="remove-a-formate"> support@ozgigs.com.au </Link> 
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="accordion-item fadeInUp wow">
                                              <h2 className="accordion-header" id="headingTen">
                                                  <button
                                                      className="accordion-button collapsed"
                                                      type="button"
                                                      data-bs-toggle="collapse"
                                                      data-bs-target="#collapseTen"
                                                      aria-expanded="true"
                                                      aria-controls="collapseTen"
                                                  >
                                                      My business provides services for two or more different categories.
                                                  </button>
                                              </h2>
                                              <div
                                                  id="collapseTen"
                                                  className="accordion-collapse collapse"
                                                  aria-labelledby="headingTen"
                                                  data-bs-parent="#accordionExample"
                                              >
                                                  <div className="accordion-body">
                                                  Currently you can only operate in one category at a time. In the future, we plan on exploring the feasibility of enabling vendors to operate in two or more categories. 
                                                  </div>
                                              </div>
                                          </div>

                                          <div className="accordion-item fadeInUp wow">
                                              <h2 className="accordion-header" id="headingEleven">
                                                  <button
                                                      className="accordion-button collapsed"
                                                      type="button"
                                                      data-bs-toggle="collapse"
                                                      data-bs-target="#collapseEleven"
                                                      aria-expanded="true"
                                                      aria-controls="collapseEleven"
                                                  >
                                                      Can I cancel my Subscription at any time?
                                                  </button>
                                              </h2>
                                              <div
                                                  id="collapseEleven"
                                                  className="accordion-collapse collapse"
                                                  aria-labelledby="headingEleven"
                                                  data-bs-parent="#accordionExample"
                                              >
                                                  <div className="accordion-body">
                                                  You can cancel your subscription at any time via the vendor dashboard.
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </section>
          <section className="privacy-policy-wr">
              <div className="container">
                  <div className="privacy-policy-content">
                      <div className="privacy-policy-text-block faq-about-us">
                          <ul>
                              <li>
                                  <h3>Contact Us</h3>
                                  <p className="pt-1">
                                    Have we missed anything? Please reach out to us. 
                                  </p>
                                  <p className="pt-1">
                                  We’d also love to hear from you about your experience with Ozgigs, whether positive or negative 
                                  </p>
                                  <p className="pt-1">
                                  You can contact us at <Link target="_blanck" to="mailto:support@ozgigs.com.au" className="remove-a-formate"> support@ozgigs.com.au </Link>  
                                  </p>
                              </li>
                          </ul>
                      </div>
                  </div>
              </div>
          </section>
          <Footer />
        </div>
      </>
    );
  };
  
  export default VendorInformationPage;