import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./includes/header";
import Footer from "./includes/footer";
import staticPagesAction from '../actions/staticPagesAction';
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const AboutUs = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(staticPagesAction());
    }, []);

    // get vendor all chat list
    const staticData = useSelector((state) => state.staticDataList);
    const [dataList, setDataList] = useState("");

    useEffect(() => {
        if (staticData && staticData?.notification && staticData?.notification?.datasss && staticData?.notification?.datasss?.status === true) {
            setDataList(staticData.notification.datasss.data)
        }
    }, [staticData]);

    useEffect(() => {
        const { pathname, hash } = location;
        if (hash) {
            const targetElement = document.querySelector(hash);
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: "smooth" });
            }
        } else {
            window.scrollTo(0, 0);
        }
    }, [location]);

    return (
        <>
            <ToastContainer />
            <div className="wrapper">
                <Header />
                <section className="privacy-policy-banner">
                    <div className="privacy-policy-banner-img">
                        <figure>
                            <img src="img/about_us_banner.png" alt="About Us" />
                        </figure>
                    </div>
                    <div className="privacy-policy-banner-content">
                        <div className="container">
                            <div className="privacy-policy-heading">
                                <h1>About Us</h1>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="privacy-policy-wr" id="AboutUs">
                    <div className="container">
                        <div className="privacy-policy-content">
                            <div className="privacy-policy-text-block faq-about-us">
                                <ul>
                                    <li>
                                        <h3>About Us</h3>
                                        <p className="pt-2">
                                            Headquartered in Sydney Australia, OzGigs is the brainchild of Cavan Te Ratana, a professional singer with over 30 years of experience in the Australian events industry.
                                        </p>
                                        <p className="pt-2">
                                            As a service provider Cavan felt the need for a dedicated event supplier marketplace. One that will offer affordability to vendors listing their services, combined with a convenient and easy way for consumers to search, find and connect.
                                        </p>
                                        <p className="pt-2">
                                            Teaming up with long time friend and professional guitarist Stan Wong they refined OzGigs into a sleek user friendly site.
                                        </p>
                                        <p className="pt-2">
                                            “Our innovative approach to online marketplaces is achieved by placing supplier promotional videos as the first point of reference. We provide consumers with free enhanced searching capabilities and an affordable vendor subscription.”
                                        </p>
                                        <p className="pt-2">
                                            The aim of OzGigs is to showcase these services on a global scale in the most affordable, accessible and vividly engaging way.
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </div>
        </>
    );
};

export default AboutUs;
